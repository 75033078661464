import React, {useState} from 'react';


const NumericValueV2 = props => {

    const {answers, question, readOnly} = props
    const [value, setValue] = useState((answers && answers.length > 0) ? answers[0].answerNumeric : question.min);

    const handleChange = (e) => {
        if(!readOnly) {
            const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

            if(numericValue.length > 0) {
                if((parseInt(numericValue) >= question.min && parseInt(numericValue) <= question.max) || question.min === question.max) {
                    setValue(numericValue);
                    const tempAnswer = {
                        questionId: question.id,
                        answerText: parseInt(numericValue).toString(),
                        assessmentId: 0,
                        answerNumeric: parseInt(numericValue),
                        answerDate: null,
                        templateAnswer: null
                    }
                    if(props.handleNewAnswer)
                        props.handleNewAnswer([tempAnswer]);
                }
            }
            else {
                setValue(numericValue);
                if(props.handleNewAnswer)
                    props.handleNewAnswer([]);
            }
        }
    }


    return (
            <div>
                <input
                        type="text" inputMode="numeric" pattern="[0-9]*"
                        className="form-control"
                        min={question.min}
                        max={question.max}
                        required={question.required}
                        value={value}
                        step={question.steps}
                        onChange={handleChange}
                        onFocus={(event) => event.target.select()}
                />
            </div>
    )
}
export default NumericValueV2;