import React, {useEffect, useReducer, useState} from 'react';
import {withUser} from "../../context/UserContext";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {Button} from "@mui/material";
import ModalSlider from "../ModalSlider/ModalSlider";
import _, {isEmpty, isEqual, reduce} from "lodash";
import './AssessmentV2.css';
import AssessmentHeaderV2 from "./AssessmentHeaderV2";
import AssessmentQuestionV2 from "./AssessmentQuestionV2";


const AssessmentV2 = (props) => {
    const [assessment, setAssessment] = useState(props.assessment);
    const [groups, setGroups] = useState([]);
    const [validations, setValidations] = useState(new Map());
    const [validAssessment, setValidAssessment] = useState(true);
    const [answers, setAnswers] = useState(new Map());
    const [questionModalView, setQuestionModalView] = useState(false);
    const [activeQuestionModal, setActiveQuestionModal] = useState(false);
    const [activeGroup, setActiveGroup] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if(!_.isEqual(assessment, props.assessment)) {
            setAssessment(props.assessment);
            getGroups();
        }
    }, [props.assessment]);
    useEffect(() => {
        getGroups()
    }, [assessment]);
    const compare = (a, b, operator, not) => {
        const operations = {
            EQUALS: (a, b) => a === b,
            GREATER: (a, b) => a > b,
            LESS: (a, b) => a < b,
            GREATER_OR_EQUAL: (a, b) => a >= b,
            LESS_OR_EQUAL: (a, b) => a <= b,
            STARTS_WITH: (a, b) => a.startsWith(b),
            ENDS_WITH: (a, b) => a.endsWith(b),
            CONTAINS: (a, b) => a.includes(b),
        };
        const result = operations[operator] ? operations[operator](a, b) : null; 
        return not ? !result: result;
    }
    const getGroups = () => {
        if(assessment.template.groups) {
            const groups = assessment.template.groups
                    .map((group) => {
                        group.open = true;
                        return group;
                    });
            setGroups(groups);
        }

    }
    const isValidForm = () => {
        if(validations.size === 0) {
            setValidAssessment(false);
            return false;
        }
        let valid = true;
        for(const [, value] of validations.entries()) {
            if(!value) {
                valid = false;
                break;
            }
        }
        setValidAssessment(valid);
        return valid;

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if(isValidForm()) {
            setAssessment({...assessment, dateEnded: new Date()});
            if(props.handleClose)
                props.handleClose(true, assessment);
        }
    }
    const toggleGroup = (index) => {
        let localAssessment = assessment;
        if(localAssessment.id > 0) {
            let template = localAssessment.template;
            let groups = template.groups;
            let group = groups[index];
            group.open = !group.open;
            groups[index] = group;
            template.groups = groups;
            localAssessment.template = template;
            setAssessment(localAssessment);
        }

    }
    const activeQuestionModalHandler = (index) => {
        setActiveQuestionModal(!activeQuestionModal);
        setActiveGroup(index);
    }
    const handleAnswer = (question, localAnswers) => {
        console.log("localAnswers",localAnswers);
        const valid = validations;
        valid.set(question.id, (!question.required || (question.required && (localAnswers && localAnswers.length > 0))));
        const ans = answers;
        ans.set(question.id, localAnswers)
        setValidations(valid);
        setAnswers(ans);
        forceUpdate();
    }
    const answeredQuestions = () => {
        const localAnswers = [];
        if(!answers) return 0;
        answers?.forEach((element) => localAnswers.push(element));
        return reduce(localAnswers, (result, answerList) => {
            if(!isEmpty(answerList)) ++result;
            return result;
        }, 0);

    }
    const shouldShow = (question) => {
        if(assessment === null || question.dependencies.length === 0)
            return true;
        let show = true;
        const dependencies = question.dependencies.sort((a, b) => (a.order > b.order) ? 1 : -1)
        dependencies.forEach((dependency) => {

            //TODO: Implement inter dependencies (AND, OR, etc.)
            const dependentQuestionId = dependency.assessmentTemplateDependentQuestionId;
            const localAnswers = answers.get(dependentQuestionId)
            const dependentQuestion = findQuestion(dependentQuestionId)
            switch(dependentQuestion.questionType) {
                case "FREE_TEXT":
                    if(localAnswers === undefined || localAnswers.length === 0 || !localAnswers[0].answerText ||
                       !compare(localAnswers[0].answerText.toLowerCase(), dependency.answerText.toLowerCase(), dependency.operator, dependency.not))
                        show = false;
                    break;
                case "SELECTION":
                    show = false;
                    if(localAnswers?.length > 0) {
                        localAnswers.forEach((answer) => {
                            if(answer.assessmentTemplateAnswerId === dependency.assessmentTemplateAnswerId)
                                show = true;
                        });
                    }
                    break;
                case "MULTIPLE_SELECTION":
                    //TODO: Implement
                    break;
                case "RANGE":
                case "NUMERIC_VALUE": {
                    if(localAnswers === undefined || localAnswers.length === 0 || !localAnswers[0].answerNumeric ||
                       !compare(localAnswers[0].answerNumeric, dependency.answerNumeric, dependency.operator, dependency.not))
                        show = false;
                }
                    break;
                case "DATE":{
                    if(localAnswers === undefined || localAnswers.length === 0 || !localAnswers[0].answerDate ||
                       !compare(new Date(localAnswers[0].answerDate.slice(0,10)).getTime(), new Date(dependency.answerDate.slice(0,10)).getTime(), dependency.operator, dependency.not))
                        show = false;

                }
                    break;
                default:
            }
        });
        return show
    }

    const findQuestion = (id) => {
        const questions = [];
        const groups = assessment.template.groups;
        groups.map((group) => questions.push(...group.questions))
        let question = questions.find((question) => question.id === id);
        if(question === undefined)
            question = null;
        return question;
    }

    return (
            <div className="assessment">
                {assessment &&
                 <>
                     <AssessmentHeaderV2 assessment={assessment}
                                         handleSubmit={handleSubmit}
                                         readOnly={props.readOnly}
                                         validationErrors={!validAssessment}
                                         onClose={props.handleClose}
                                         changeQuestionView={() => setQuestionModalView(!questionModalView)}/>

                     <div className={"assessment-contents"}>
                         <div className={"groups"}>
                             {groups?.map((group, index) => {
                                 return (
                                         <div className="group" key={index}>
                                             <div className="row group-control">
                                                 <div className="col-md-3">{group?.name}</div>
                                                 <div className="col-md-8 instructions">{group?.instructions?.trim().length > 0 ? group.instructions : assessment.template.instructions}</div>
                                                 <div className="col-md-1 icon text-right">
                                                     {group?.open && !questionModalView &&
                                                      <CaretDownOutlined onClick={() => toggleGroup(index)}/>
                                                     }
                                                     {!group?.open && !questionModalView &&
                                                      <CaretUpOutlined onClick={() => toggleGroup(index)}/>
                                                     }
                                                     {questionModalView &&
                                                      <Button onClick={() => activeQuestionModalHandler(index)}>View</Button>
                                                     }
                                                 </div>
                                             </div>
                                             {group?.open && !questionModalView ?
                                              <div className={"questions"}>
                                                  {group?.questions?.map((question, index) => (
                                                          shouldShow(question) &&
                                                          <AssessmentQuestionV2 key={index}
                                                                                index={index}
                                                                                question={question}
                                                                                assessmentId={assessment.id}
                                                                                readOnly={props.readOnly}
                                                                                submitted={submitted}
                                                                                onAnswer={handleAnswer}
                                                                                valid={!submitted || (submitted && validations.get(question.id))}/>

                                                  ))}
                                              </div> : null}
                                         </div>)
                             })}
                         </div>
                     </div>
                 </>
                }
                {activeQuestionModal && (
                        <ModalSlider
                                activeGroup={groups[activeGroup]}
                                assessmentId={assessment.id}
                                firstGroup={isEqual(activeGroup, 0)}
                                lastGroup={isEqual(activeGroup + 1, groups.length)}
                                nextActiveGroup={() => setActiveGroup(activeGroup + 1)}
                                previousActiveGroup={() => setActiveGroup(activeGroup - 1)}
                                onAnswer={handleAnswer}
                                readOnly={props.readOnly}
                                completedQuestions={`${answeredQuestions()}/${answers.size}`}
                                remainingQuestions={answers.size - answeredQuestions()}
                                submitted={submitted}
                                validations={validations}
                                handleClose={() => activeQuestionModalHandler(null)}/>
                )}
            </div>
    )
}


export default withUser(AssessmentV2);