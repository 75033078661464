import React, {useEffect, useState} from 'react';
import {FormControlLabel, Radio} from '@mui/material';
import './SelectionV2.css'
import _ from "lodash";


const SelectionV2 = (props) => {
    const [answers, setAnswers] = useState(props.answers);
    useEffect(() => {
        if(!_.isEqual(props.answers, answers)) {
            setAnswers(props.answers);
        }
    }, [props.answers]);
    const handleChange = (event) => {
        if(!props.readOnly) {
            const templateAnswer = props.question.answers.find(item => item.id === parseInt(event.target.id));
            let answer = answers?.length > 0 ? answers[0] : {
                questionId: props.question.id
            };
            answer.assessmentTemplateAnswerId = parseInt(event.target.id);
            answer.answerText = templateAnswer.hasFreeText ? answer.answerText : templateAnswer.answer;
            setAnswers([answer]);
            if(props.handleNewAnswer)
                props.handleNewAnswer([answer]);
        }
    }
    const handleKeyUp = (e, index) => {
        if(index > -1) {
            const localAnswers = answers;
            localAnswers[index].answerText = e.target.value;
            setAnswers(localAnswers);
        }
    }

    return (
            <div className={"selection"}>
                {props.question.answers.map((templateAnswer, index) => {
                    const answerIndex = answers.findIndex(a => a.assessmentTemplateAnswerId === templateAnswer.id);
                    const checked = answers.findIndex(a => a.assessmentTemplateAnswerId === templateAnswer.id) > -1;
                    const rootClass = props.readOnly ? 'Radio read-only' : 'Radio';
                    return (
                            <div className="answer mx-3" key={index.toString()}>

                                <FormControlLabel
                                        classes={{root: 'FormControlLabel'}}
                                        control={
                                            <Radio
                                                    classes={{root: rootClass, checked: 'checked'}}
                                                    id={templateAnswer.id.toString()}
                                                    value={templateAnswer.answer}
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    key={index}
                                            />
                                        }
                                        label={templateAnswer.answer}
                                />
                                {(answerIndex > -1 && templateAnswer.hasFreeText) ?
                                 <span>
                                 {props.readOnly ? answerIndex > -1 ? answers[answerIndex].answerText : ""
                                                 : <input
                                          onKeyUp={(e) => handleKeyUp(e, answerIndex)}
                                          defaultValue={answerIndex > -1 ? answers[answerIndex].answerText : ""}
                                          onBlur={() => {
                                              if(!props.readOnly && props.handleNewAnswer)
                                                  props.handleNewAnswer(answers)
                                          }
                                          }
                                  />}
                                 </span> : null}
                            </div>
                    )
                })}
            </div>

    );
}


export default SelectionV2;