import React, {useEffect, useState} from 'react';
import _ from "lodash";

const FreeTextV2 = (props) => {
    const [answers, setAnswers] = useState(props.answers);
    const [text, setText] = useState((props?.answers.length > 0) ? props.answers[0].text : "")
    useEffect(() => {
        if(!_.isEqual(props.answers, answers)) {
            setAnswers(props.answers);
            setText((props?.answers.length > 0) ? props.answers[0].text : "")
        }
    }, [props.answers]);
    
    const onBlur = () => {

        if(!props.readOnly) {
            let answer = answers?.length > 0 ? answers[0] : null;
            if(answer === null)
                answer = {questionId: props.question.id};
            setAnswers((text?.length > 0 ? [{...answer, answerText: text}] : []));
            if(props.handleNewAnswer)
                props.handleNewAnswer((text?.length > 0 ? [{...answer, answerText: text}] : []))
        }
    }
    const handleInputChange = (event) => {
        if(!props.readOnly) {
            setText(event.target.value);
        }
    }

    return (
            <div>
                {props.readOnly ?
                 <div>
                     {this.state.text}
                 </div>
                                : <input
                         className="form-control"
                         type="text"
                         onBlur={onBlur}
                         required={props.question.required}
                         defaultValue={text}
                         onChange={handleInputChange}

                 />
                }
            </div>
    );
}


export default FreeTextV2;