import React, {useEffect, useState} from 'react';
import _ from "lodash";

const DateV2 = (props) => {
    const [answers, setAnswers] = useState(props.answers);
    const [date, setDate] = useState((props.answers && props.answers.length > 0 && props.answers[0].answerDate) ? props.answers[0].answerDate.slice(0, 10) : null);

    useEffect(() => {
        if(!_.isEqual(props.answers, answers)) {
            setAnswers(props.answers);
            setDate((props.answers && props.answers.length > 0 && props.answers[0].answerDate) ? props.answers[0].answerDate?.slice(0, 10) : null);
        }
    }, [props.answers]);

    const handleChange = (event) => {
        if(!props.readOnly) {
            let answer = answers?.length > 0 ? answers[0] :
                    {
                        questionId: props.question.id,
                    }
            answer = {...answer,answerDate:event.target.value,answerText:event.target.value};                    
            setAnswers([answer]);
            if(props.handleNewAnswer)
                props.handleNewAnswer([answer])
        }
    }

    return (
            <div>
                {props.readOnly ?
                 <div>
                     {date}
                 </div> :
                 <input
                         className="form-control"
                         type="date"
                         required={props.question.required}
                         defaultValue={date}
                         onChange={(e) => handleChange(e)}
                 />}
            </div>

    );
}

export default DateV2;