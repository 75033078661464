import React, {useEffect, useState} from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";
import './SelectionV2.css'
import _ from "lodash";


const MultipleSelectionV2 = (props) => {

    const [answers, setAnswers] = useState(props.answers);

    const handleChange = (event) => {

        if(!props.readOnly) {
            const templateAnswer = props.question.answers.find(item => item.id === parseInt(event.target.id));
            const localAnswers = answers;
            if(event.target.checked) { //just checked
                const answer = {
                    questionId: props.question.id,
                    answerText: templateAnswer.hasFreeText ? "" : templateAnswer.answer,
                    assessmentTemplateAnswerId: parseInt(event.target.id)
                }
                localAnswers.push(answer);
            }
            else {
                const index = answers.findIndex(item => item.assessmentTemplateAnswerId === parseInt(event.target.id));
                if(index > -1) {
                    localAnswers.splice(index, 1);
                }
            }
            setAnswers(localAnswers);
            if(props.handleNewAnswer)
                props.handleNewAnswer(localAnswers);
        }

    }

    const handleKeyUp = (e, index) => {
        if(index > -1) {
            const localAnswers = answers;
            localAnswers[index].answerText = e.target.value;
            setAnswers(localAnswers);
        }

    }

    useEffect(() => {
        if(!_.isEqual(props.answers, answers)) {
            setAnswers(props.answers);
        }
    }, [props.answers]);

    return (
            <div className={"selection"}>
                {props.question.answers.map((templateAnswer, index) => {
                    const answerIndex = answers.findIndex(a => a.assessmentTemplateAnswerId === templateAnswer.id);
                    const checked = answerIndex > -1;
                    const rootClass = props.readOnly ? 'Checkbox read-only' : 'Checkbox';
                    return (<div className="answer mx-3" key={index.toString()}>
                                {/*<div className="form-check form-check-inline mx-3">*/}
                                <FormControlLabel
                                        classes={{root: 'FormControlLabel'}}
                                        label={templateAnswer.answer}
                                        control={
                                            <Checkbox
                                                    classes={{root: rootClass, checked: "checked"}}
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    id={templateAnswer.id.toString()}
                                            />
                                        }
                                />
                                {(answerIndex > -1 && templateAnswer.hasFreeText) ?
                                 <span>
                                 {props.readOnly ? answerIndex > -1 ? answers[answerIndex].answerText : ""
                                                 : <input
                                          onKeyUp={(e) => handleKeyUp(e, answerIndex)}
                                          defaultValue={answerIndex > -1 ? this.state.answers[answerIndex].answerText : ""}
                                          onBlur={() => {
                                              if(!props.readOnly && props.handleNewAnswer)
                                                  props.handleNewAnswer(answers)
                                          }}
                                  />}
                                           </span> : null}
                            </div>
                    )
                })}

            </div>
    )
}
export default MultipleSelectionV2;