import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {UserContext, withUser} from "../context/UserContext";
import {Auth} from "../util/auth";
import impactivo from "../images/logoimpactivo-full.png";
import "./LandingPage.css";
import {Rest} from "../util/rest";
import moment from "moment";
import {toast} from "react-toastify";
import {Button} from "@mui/material";
import {useParams} from "react-router";
import AssessmentV2 from "./assessmentV2/AssessmentV2";

const LandingPage = () => {
    const { urlKey } = useParams();
    const {user, setUser} = useContext(UserContext);
    const [batchAssessment, setBatchAssessment] = useState(null);
    const [assessment, setAssessment] = useState(null);
    const [showAssessment, setShowAssessment] = useState(false);
    const [shouldShowAssessment, setShouldShowAssessment] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        if(!user && urlKey)
            Auth.login(urlKey)
                    .then((authResult) => {
                        if(authResult.authenticated) {
                            setUser(authResult.user);
                        }
                        else {
                            setUser(null);
                        }
                    });

    }, [setUser, urlKey, user])    //Authenticate using UUID

    useEffect(() => {
        if(user && urlKey && (!batchAssessment || batchAssessment.urlKey !== urlKey)) {
            Rest.authFetch(user, "/rest/assessment/batch/" + urlKey,
                           {
                               headers: {
                                   accept: 'application/json'
                               }
                           })
                    .then(response => {
                        if(response) {
                            setBatchAssessment(response);
                            setAssessment(response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
        }
        setReload(false);
    }, [batchAssessment, urlKey, user, reload]);  //Load main assessment

    useEffect(() => {

        if(shouldShowAssessment && batchAssessment) {
            if(batchAssessment.assignmentType === "ANONYMOUS") {
                Rest.authFetch(user, `/rest/assessments/batch/clone/${batchAssessment.id}`)
                        .then(response => {
                            if(response) {
                                setAssessment(response);
                                setShowAssessment(true);
                            }
                        });
            }
            else {
                setAssessment(batchAssessment);
                setShowAssessment(true);
            }
        }
        else
            setShowAssessment(false)
    }, [batchAssessment, shouldShowAssessment, user])  //Clone assignment if anonymous           


    function save(assessment) {
        Rest.authFetch(user, "/rest/assessment", {
            method: "PUT",
            body: JSON.stringify(assessment)
        })
                .then(response => {
                    if(response) {
                        if(response && response.dateEnded !== null) { toast.success("Assessment submitted");}

                    }
                })
                .catch(err => {
                    console.log(err);

                });
    }

    function handleClose(saveAssessment, assessment) {
        setShowAssessment(false);
        setShouldShowAssessment(false);
        if(saveAssessment)
            save(assessment);
        setReload(true);
    }

    return (
            <div className="landing-page">
                {showAssessment && assessment ?
                 <AssessmentV2 assessment={assessment} handleClose={handleClose}/>
                                              :
                 <>
                     <div className="row header">
                         <div className="col-sm-4 col-md-3 branding">
                             <img id="logoImpactivo" src={impactivo} style={{height: 'auto'}}
                                  alt="Impactivo"/>
                         </div>
                     </div>
                     <div className="row content">
                         {!batchAssessment ?
                          <div className="col-12 text-center error">
                              Assessment not available
                          </div>
                                           :
                          <div className="col-12">
                              {batchAssessment.assignmentType === "TARGETED" ?
                               <div className="row my-auto">
                                   <div className="col-12 text-center welcome">
                                       Welcome <span className="name"> {batchAssessment.personName}</span>
                                   </div>
                               </div> : null}
                              <div className="row my-auto">
                                  <div className="col-12 text-center message">
                                      You have the following assessment available
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12 text-center">
                                      <Button variant="contained"
                                              className="assessment-button"
                                              onClick={() => {setShouldShowAssessment(true)}}>

                                          {batchAssessment.assignable.name}
                                      </Button>
                                  </div>
                              </div>
                              <div className="row my-auto">
                                  <div className="col-8 offset-2 text-center description">
                                      {batchAssessment.template.description}
                                  </div>
                              </div>
                              {batchAssessment.dateExpires ?
                               <div className="row my-auto">
                                   <div className="col-8 offset-2 text-center">
                                       Expires on: {moment(batchAssessment.dateExpires).format("MM/DD/YYYY")}
                                   </div>
                               </div> : null
                              }
                              {batchAssessment.dateEnded ?
                               <div className="row my-auto">
                                   <div className="col-8 offset-2 text-center">
                                       Completed on: {moment(batchAssessment.dateEnded).format("MM/DD/YYYY")}
                                   </div>
                               </div> : null
                              }
                          </div>}
                     </div>
                 </>
                }
            </div>
    );
}

export default withUser(LandingPage);