import React, {useEffect} from 'react';
import {Rest} from "../../util/rest";
import {toast} from "react-toastify";
import {withUser} from "../../context/UserContext";
import * as InputTypes from "../assessmentV2/inputTypes";

const AssessmentQuestionV2 = (props) => {
    const [answers, setAnswers] = React.useState([]);
    const capitalizeWords = (value) => {
        let string = value.toLowerCase().split("_");
        string = string.map((word) => word[0].toUpperCase() + word.slice(1))
        string.push("V2");
        return string.join("");
    }
    const QuestionType=InputTypes[capitalizeWords(props.question.questionType)];
    
    useEffect(() => {
        getAnswers();
        return function cleanup() {
            setAnswers([]);
        };
    }, []);

     
    const getAnswers = () => {
        Rest.authFetch(props.user, "/rest/assessment/" + props.assessmentId + "/" + props.question.id + "/answers")
                .then(response => {
                    if(response) {
                        setAnswers(response);
                        handleNewAnswer(response);
                    }
                    else {
                        toast.error("Error loading Answers");
                    }
                });
    }
    const handleNewAnswer = (localAnswers) => {
        localAnswers.forEach((answer) => {
            answer.assessmentId = props.assessmentId;
            answer.questionId = props.question.id;
        })
        saveAnswer(localAnswers);
    }
    const saveAnswer = (answers) => {
        console.log("saveAnswer - answers", answers);
        Rest.authFetch(props.user, "/rest/assessment/" + props.assessmentId + "/" + props.question.id + "/answers", {
            method: "PUT",
            body: JSON.stringify(answers)
        })
                .then(response => {
                    if(response) {
                        setAnswers(response);
                        console.log("onAnswer - response", response);
                        if(props.onAnswer)
                            props.onAnswer(props.question, response);
                    }
                    else {
                        toast.error("Error saving Answer");
                    }
                });
    }

    return (
            <div className={"flex-fill question p-3 mb-3" + (!props.valid ? " question-error" : "") + (props.readOnly ? " read-only" : "")}>
                {props.error ?
                 <div className="question-error row">
                     <div className="col-md-12">
                         {props.error}
                     </div>
                 </div> : null
                }
                <div className="row">
                    <div className={"col-md-12"}>
                        <div className={"row"}>
                            <div className="col-md-12 align-items-center mb-4 font-weight-bold">
                                <span>{props.question.question}{props.question.required ? "*" : ""}</span>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-md-12">
                                <QuestionType handleNewAnswer={handleNewAnswer}
                                              question={props.question}
                                              answers={answers}
                                              readOnly={props.readOnly}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    );
}

export default withUser(AssessmentQuestionV2);