import React from 'react';
import {Button} from "@mui/material";
import impactivo from '../../images/logoimpactivo.png'



const AssessmentHeaderV2 = (props) => {
	
	return (
			<div className="header">
				<div className={"p-3 shadow-sm border-bottom row"}>
					<div className={"col-md-1 col-sm-1 branding"}>
						<img id="logoImpactivo" src={impactivo} style={{ height: 'auto' }}
							 alt="Impactivo" />
					</div>
					<div className={"col-md-9 col-sm-9"}>
						<div className="row">
							<div className="col-md-12 person-name">
								{props.assessment?.personName?props.assessment.personName:""}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 assessment-name">
								{props.assessment?.assignable.name?props.assessment.assignable.name:""}
							</div>
						</div>
					</div>


					<div className={"col-md-2 col-sm-2 my-auto"}>

						<div className="row">
							<div className="col-md-4">
								{!props.readOnly ?
								 <Button onClick={(e) => props.handleSubmit(e, true)} variant="contained"
										 className="editorButton">
									 Submit{props.assessment?.assignmentType === 'ANONYMOUS_TAKEN' && " and New"}
								 </Button> : null}
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<Button onClick={() =>props.changeQuestionView()} variant="contained"
									className="editorButton">
									Question View
								</Button>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<Button onClick={() => props.onClose(false)} variant="contained"
										className="editorButton">
									Exit
								</Button>
							</div>
						</div>

					</div>


				</div>
				{props.validationErrors ?
				 <div className="row errors">
					 <div className="col-md-12">There are some errors in this assessment!</div>
				 </div> : null
				}
			</div>

	)
}


export default AssessmentHeaderV2;