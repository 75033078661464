import React, { useState } from 'react'

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent, 
} from '@mui/material';
import {IconButton, Typography }from '@mui/material';
import {makeStyles} from '@mui/styles';
import { isEqual } from 'lodash';
import "../assessmentV2/AssessmentV2.css";
import {Close} from "@mui/icons-material";
import AssessmentQuestionV2 from "../assessmentV2/AssessmentQuestionV2";

// make a active group index for next button to go to next group
// all question - all answers = remaing

const ModalSlider = (props) => {
	const {
		activeGroup: { name, questions },
		assessmentId,
		completedQuestions,
		firstGroup,
		handleClose,
		lastGroup,
		nextActiveGroup,
		onAnswer,
		previousActiveGroup,
		readOnly,
		remainingQuestions,
		submitted,
		validations
	} = props;

	const [slideIndex, setSlideIndex] = useState(0);
	const [previousGroupSlide, setPreviousGroupSlide] = useState(0);

	const previousSlideHandler = () => {
		if (slideIndex > 0) setSlideIndex(slideIndex - 1)
	};

	const nextSlideHandler = () => {
		if (slideIndex < questions.length - 1) setSlideIndex(slideIndex + 1)
	};

	const previousGroupHandler = () => {
		previousActiveGroup();
		setSlideIndex(previousGroupSlide);
	};

	const nextGroupHandler = () => {
		nextActiveGroup();
		setPreviousGroupSlide(slideIndex);
		setSlideIndex(0);
	};

	return (
		<Dialog open={true} fullScreen onClose={handleClose}>
			<DialogTitle onClose={handleClose}>{name} {slideIndex + 1}/{questions.length}</DialogTitle>
			<DialogContent>
				<AssessmentQuestionV2 key={slideIndex}
					index={slideIndex}
					question={questions[slideIndex]}
					assessmentId={assessmentId}
					readOnly={readOnly}
					submitted={submitted}
					onAnswer={onAnswer}
					valid={!submitted || (submitted && validations.get(questions[slideIndex].id))} />
			</DialogContent>
			<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<div>Questions Completed: {completedQuestions}</div>
					<div>Questions Remaining: {remainingQuestions}</div>
				</div>
				<div style={{ minWidth: '325px', display: 'flex', justifyContent: 'space-between' }}>
					<Button
						variant="outlined"
						style={{ width: '160px' }}
						onClick={isEqual(slideIndex, 0) ? previousGroupHandler : previousSlideHandler}
						disabled={firstGroup && isEqual(slideIndex, 0)}>
						{isEqual(slideIndex, 0) ? 'Previous Group' : 'Previous'}
					</Button>
					{isEqual(slideIndex + 1, questions.length)
						? (
							<Button
								color="primary"
								variant="outlined"
								onClick={lastGroup ? handleClose : nextGroupHandler}
								style={{ color: 'green', borderColor: 'green', width: '160px' }}>
								{lastGroup ? 'Finish' : 'Next Group'}
							</Button>
						)
						: (
							<Button
								color="primary"
								variant="outlined"
								onClick={nextSlideHandler}
								style={{ color: 'green', borderColor: 'green', width: '160px' }}>
								Next
							</Button>
						)}
				</div>
			</DialogActions>
		</Dialog>
	)
}

const styles = makeStyles( (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const DialogTitle = (({ children, classes, onClose, ...other }) => (
	<DialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
				<Close />
			</IconButton>
		) : null}
	</DialogTitle>
));

// ModalSlider.propTypes = { }

export default ModalSlider