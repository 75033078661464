import React, {useEffect, useState} from 'react';
import {Slider} from "@mui/material";


const RangeV2 = (props) => {

    const min = props.question.min;
    const max = props.question.max;
    const steps = props.question.steps;
    const questionId = props.question.id;
    const answers = props.answers;
    const [answer, setAnswer] = useState((answers && answers.length > 0) ? answers[0] : {
        answerNumeric: props.question.min,
        questionId: questionId
    });
    const [marks, setMarks] = React.useState([{value: min, label: min}, {value: max, label: max}]);

    const handleChange = (e) => {
        if(!props.readOnly && !isNaN(e.target.value)) {
            setAnswer({
                          ...answer,
                          answerNumeric: parseInt(e.target.value),
                          answerText: parseInt(e.target.value).toString()
                      });
        }
    }

    useEffect(() => {
        if(props.handleNewAnswer)
            props.handleNewAnswer([answer])
    }, [answer])

    useEffect(() => {
        const newMarks = [];
        for(let i = min; i <= max; i++) {
            newMarks.push({value: i, label: i});
        }
        setMarks(newMarks);
    }, [steps])

    return (
            <>
                <div className="wrap w-75 mx-auto">
                    <Slider
                            value={answer.answerNumeric}
                            type={"MinRange"}
                            min={min}
                            max={max}
                            step={steps}
                            marks={marks}
                            disabled={props.readOnly}

                            // showButtons={true}
                            valueLabelDisplay="auto"
                            onChange={handleChange}
                            sx={{
                                color: "var(--smartpcmh-green)",
                                "&.Mui-disabled": {
                                    color: "var(--smartpcmh-green)", // Ensures the same color even when disabled
                                    opacity: 1, // Remove the default dimming effect
                                },
                            }}
                    />
                </div>
            </>
    )
}
export default RangeV2;